import { useCallback, useState } from "react";
import styled from "styled-components/macro";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Card } from "../design-components/Card/Card";
import React from "react";
import { ShopPack } from "../types/eventPayloads";
import { PaymentModal } from "../Checkout/PaymentModal";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY ?? "");

const Wrapper = styled.div`
  && {
    .cardContainer {
      text-align: center;
      margin-bottom: 3vh;

      h2 {
        margin-bottom: 0;
      }

      p:first-child {
        margin-top: 0;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .cardContents {
      padding: 2rem;

      h3 {
        font-family: "Righteous", sans-serif;
        font-size: 2rem;
        font-weight: normal;
        text-align: center;
        margin: 0;
      }

      p {
        text-align: center;
        font-size: 1.8rem;
        width: 80%;
        margin: 1rem auto;
      }
    }

    h2.shop-section {
      font-style: italic;
      font-size: 2rem;
      text-align: center;
      margin-bottom: 2vh;
    }
  }
`;

type Props = {
  essentialPack: ShopPack;
  refetchUpgradeState: () => void;
};

export const UpgradeToPhoneIn = ({
  essentialPack,
  refetchUpgradeState,
}: Props) => {
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [cart, setCart] = useState<Array<ShopPack>>([]);
  const [lastRefresh, setLastRefresh] = useState(new Date());

  const buyPack = useCallback(
    (pack: ShopPack) => {
      setCart([pack]);
      setShowCheckoutModal(true);
    },
    [setShowCheckoutModal],
  );

  const hideCheckout = useCallback(() => {
    setShowCheckoutModal(false);
    refetchUpgradeState();
    setLastRefresh(new Date());
  }, [refetchUpgradeState, setLastRefresh, setShowCheckoutModal]);

  return (
    <Wrapper>
      {showCheckoutModal && (
        <Elements stripe={stripePromise}>
          <PaymentModal cart={cart} handleClose={hideCheckout} />
        </Elements>
      )}
      <Card
        title="Can't come up with anything?"
        submitButtonProps={{
          disabled: false,
          onClick: () => buyPack(essentialPack),
          textLabels: {
            enabled: `Upgrade`,
            disabled: `Not Available`,
            submitting: `Completing Purchase...`,
          },
          resetOnChange: lastRefresh,
        }}
      >
        <p>
          Users with the Essential Expansion Pack can get response suggestions
          from AI. You'll also get 30+ additional prompts to play!
        </p>
      </Card>
    </Wrapper>
  );
};
