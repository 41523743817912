import * as React from "react";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import * as Sentry from "@sentry/browser";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Card } from "../design-components/Card/Card";
import { PusherContext } from "../pusherContext";
import { TCLogo } from "../TCLogo/TCLogo";
import { ShopPack } from "../types/eventPayloads";
import { PaymentModal } from "../Checkout/PaymentModal";
import { Helmet } from "react-helmet";
import Decimal from "decimal.js";
import { trackPage } from "../SegmentManager";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY ?? "");

const Wrapper = styled.div`
  && {
    .cardContainer {
      text-align: center;
      margin-bottom: 3vh;

      h2 {
        margin-bottom: 0;
      }

      p:first-child {
        margin-top: 0;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .cardContents {
      padding: 2rem;

      h3 {
        font-family: "Righteous", sans-serif;
        font-size: 2rem;
        font-weight: normal;
        text-align: center;
        margin: 0;
      }

      p {
        text-align: center;
        font-size: 1.8rem;
        width: 80%;
        margin: 1rem auto;
      }
    }

    h2.shop-section {
      font-style: italic;
      font-size: 2rem;
      text-align: center;
      margin-bottom: 2vh;
    }
  }
`;

export default function OutOfPrompts() {
  const { outOfPromptsInfo, pusherPin } = useContext(PusherContext);
  const [suggestedPacks, setSuggestedPacks] = useState<ShopPack[]>([]);
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [cart, setCart] = useState<Array<ShopPack>>([]);

  useEffect(() => {
    trackPage("Game", "Out of Prompts");
  }, []);

  const getSuggestedPacks = useCallback(async () => {
    try {
      const result = (
        await axios.get(
          `${process.env.REACT_APP_BACKEND_HOST}/room/${pusherPin}/suggestedPacks`,
        )
      ).data as ShopPack[];

      setSuggestedPacks(result);
      setLastRefresh(new Date());
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [pusherPin]);

  const handleStartGame = useCallback(async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_HOST}/room/${pusherPin}/startGame`,
      );
    } catch (error) {
      console.error(error);
    }
  }, [pusherPin]);

  useEffect(() => {
    getSuggestedPacks();
  }, [getSuggestedPacks, outOfPromptsInfo]);

  const buyPack = useCallback(
    (pack: ShopPack) => {
      setCart([pack]);
      setShowCheckoutModal(true);
    },
    [setShowCheckoutModal],
  );

  const hideCheckout = useCallback(() => {
    getSuggestedPacks();
    setShowCheckoutModal(false);
  }, [getSuggestedPacks, setShowCheckoutModal]);

  if (outOfPromptsInfo === null) {
    return null;
  }

  return (
    <Wrapper>
      <Helmet>
        <title>Got 'Em: Out of Prompts</title>
      </Helmet>

      {showCheckoutModal && (
        <Elements stripe={stripePromise}>
          <PaymentModal cart={cart} handleClose={hideCheckout} />
        </Elements>
      )}

      <TCLogo subtitle="Out of Prompts" />
      <Card
        title="Whoa!"
        submitButtonProps={{
          textLabels: {
            enabled: "Next Round",
            disabled: "No Prompts Remaining",
            submitting: "Starting Round...",
          },
          disabled: !outOfPromptsInfo.morePromptsAvailable,
          onClick: handleStartGame,
        }}
      >
        <p>
          You've played all your prompts this session! Add more to keep the
          party going.
        </p>
        <p>
          Prompts can be added by purchasing more packs. Every user in the room
          contributes the packs they own to the game.
        </p>
      </Card>

      <h2 className="shop-section">May we suggest...</h2>

      {suggestedPacks.map((pack) => {
        const priceDollars = new Decimal(pack.priceCents).dividedBy(100);
        const priceText = `$${priceDollars.toFixed(2)}`;

        return (
          <Card
            key={pack.packId}
            submitButtonProps={{
              disabled: false,
              onClick: () => buyPack(pack),
              textLabels: {
                enabled: `Purchase (${priceText})`,
                disabled: `Not Available`,
                submitting: `Completing Purchase...`,
              },
              resetOnChange: lastRefresh,
            }}
          >
            <h3>{pack.packName}</h3>
            <p>{pack.marketingDescription}</p>
          </Card>
        );
      })}
    </Wrapper>
  );
}
