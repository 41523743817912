import { gql } from "@apollo/client";

const GET_USER_HAS_UPGRADED = gql`
  query userHasUpgraded {
    me {
      hasUpgraded
    }
    essentialPack {
      ownedByUser
      packId
      packName
      priceCents
      fullPriceCents
      isSeasonal
      marketingDescription
      invoiceId
      appleProductId
      estimatedPromptCount
    }
  }
`;

export default GET_USER_HAS_UPGRADED;
